@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
// Fonts
@import './assets/fonts/fonts.scss';
// Angular CDK
@import '@angular/cdk/overlay-prebuilt.css';
// Custom
@import './styles/main.scss';
